var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Card',{attrs:{"title":'Classifications (' + _vm.author.pubClassificationCounts.publicationsByPrimaryClassification.length + ')'}},[_c('BTable',{attrs:{"small":"","striped":"","fields":_vm.fields,"items":_vm.combinedClassificataions,"per-page":_vm.itemsToShow,"sort-icon-left":""},scopedSlots:_vm._u([{key:"cell(description)",fn:function(row){return [(_vm.isPublic)?_c('RouterLink',{attrs:{"to":{
            name: 'FreeToolsSearchMsc',
            query:{
              text: row.item.code,
            },
          }}},[_c('div',[_vm._v(" "+_vm._s(row.item.code)+" - "+_vm._s(row.value)+" ")])]):_c('RouterLink',{attrs:{"to":{
            name:'SearchMsc',
            query:{
              text: row.item.code,
            },
          }}},[_vm._v(" "+_vm._s(row.item.code)+" - "+_vm._s(row.value)+" ")])]}},{key:"cell(publications)",fn:function(row){return [(_vm.isPublic)?_c('div',[_vm._v(" "+_vm._s(row.item.publications)+" ")]):_c('RouterLink',{attrs:{"to":{
            name:'PublicationsSearch',
            query: {
              query:`auid:${_vm.author.id} pc:${row.item.code}`,
              sort:'newest',
            }
          }}},[_vm._v(" "+_vm._s(row.item.publications)+" ")])]}},{key:"cell(citations)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.citations)+" ")]}}])}),(_vm.author.pubClassificationCounts.publicationsByPrimaryClassification.length > _vm.perPage)?_c('BButton',{staticClass:"mr-1",attrs:{"disabled":_vm.itemsToShow >= _vm.author.pubClassificationCounts.publicationsByPrimaryClassification.length,"variant":"primary","title":"Show more classifications","name":"Show more classifications button"},on:{"click":_vm.showMoreItems}},[_vm._v(" Show More ")]):_vm._e(),(_vm.author.pubClassificationCounts.publicationsByPrimaryClassification.length > _vm.perPage && _vm.itemsToShow < _vm.author.pubClassificationCounts.publicationsByPrimaryClassification.length)?_c('BButton',{staticClass:"mr-1",attrs:{"variant":"primary","title":"Show all classifications","name":"Show all classifications button"},on:{"click":_vm.showAllItems}},[_vm._v(" Show All ")]):_vm._e(),(_vm.author.pubClassificationCounts.publicationsByPrimaryClassification.length > _vm.perPage && _vm.itemsToShow == _vm.author.pubClassificationCounts.publicationsByPrimaryClassification.length)?_c('BButton',{staticClass:"mr-1",attrs:{"variant":"primary","name":'Show ' + _vm.perPage + ' classifications button'},on:{"click":_vm.collapseItems}},[_vm._v(" Show "+_vm._s(_vm.perPage)+" ")]):_vm._e(),(_vm.author.pubClassificationCounts.publicationsByPrimaryClassification.length > _vm.perPage)?_c('BButton',{staticClass:"mr-1",attrs:{"disabled":_vm.itemsToShow <= _vm.perPage,"variant":"primary","title":"Show fewer classifications","name":"Show less classifications button"},on:{"click":_vm.showLessItems}},[_vm._v(" Show Less ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }