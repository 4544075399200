
import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator'
import { Results } from '@/global-types'
import { Journal } from './types'
import { Card } from '@/components/card'
import { updateMathJax } from '@/utils/utils'
import { SearchPagination } from '@/components/search-results'

type KeyText = {
  [key: string]: string
}

@Component({
  components: {
    Card,
    SearchPagination,
  },
})
export default class ResultsComponent extends Vue {
  @Prop({ type: Boolean, default: false }) loading!: boolean
  // @Prop({ default: undefined }) authorSerialsIds!: AuthorSerialsIds
  @Prop({ required: true }) serials!: Results<Journal>
  @Prop({ default: false, type: Boolean }) nestedComponentFormat!: boolean

  @PropSync('currentPage', { type: Number }) syncedCurrentPage!: number
  @PropSync('pageSize', { type: Number }) syncedPageSize!: number
  @PropSync('sortKey', { type: String }) syncedSortKey!: string
  @PropSync('orderKey', { type: String }) syncedOrderKey!: string
  @PropSync('activeStatus', { type: String, default: '' }) syncedActiveStatus!: string

  sortedKeyText: KeyText = {
    title: 'Title',
    shortTitle: 'Abbreviated Title',
    relevance: 'Relevance',
  }

  orderKeyText: KeyText = {
    asc: 'ASC',
    desc: 'DESC',
  }

  // activeStatus = ''
  activeStatusOptions = [
    {
      value: 'CURRENT',
      text: 'Currently Indexed',
    },
    {
      value: 'HISTORICAL',
      text: 'No Longer Indexed',
    },
    {
      value: '',
      text: 'All',
    },
  ]

  //
  // WATCHERS
  //
  // @Watch('syncedPageSize', { immediate: true })
  // updatePageSize(newSize: number) {
  //   this.syncedPageSize = newSize
  //   if (newSize !== this.parentPageSize) {
  //     this.parentPageSize = newSize
  //   }
  // }

  // @Watch('syncedCurrentPage', { immediate: true })
  // updateCurrentPage(newPage: number) {
  //   this.syncedCurrentPage = newPage
  //   if (newPage !== this.parentCurrentPage) {
  //     this.parentCurrentPage = newPage
  //   }
  // }

  // @Watch('parentPageSize', { immediate: true })
  // updateSyncedPageSize() {
  //   this.syncedPageSize = this.parentPageSize
  // }

  // @Watch('parentCurrentPage', { immediate: true })
  // updateSyncedCurrentPage() {
  //   this.syncedCurrentPage = this.parentCurrentPage
  // }

  // @Watch('localPageSize', { immediate: true })
  // updateSyncedPageSize(newLocalPageSize) {
  //   if (newLocalPageSize !== this.syncedPageSize) {
  //     localStorage.setItem(this.$route.name + 'PageSize', newLocalPageSize)
  //     this.syncedPageSize = newLocalPageSize
  //     // this.$set(this.syncedCurrentPage, newLocalPageSize)
  //     this.updateCurrentPage(1)
  //   }
  // }
  //
  // @Watch('syncedPageSize', { immediate: true })
  // updateLocalPageSize(newSyncPageSize) {
  //   if (newSyncPageSize !== this.localPageSize) {
  //     // Don't update the localStorage here. The user didn't select this new page size, it came from something like a URL (or being updated by the localPageSize)
  //     this.localPageSize = newSyncPageSize
  //   }
  // }

  //
  // COMPUTER PROPERTIES
  //

  get isOrderVisible() {
    return ['title', 'shortTitle'].includes(this.syncedSortKey)
  }

  get totalPaginationRows() {
    return this.serials.total > 1000 ? 1000 : this.serials.total
  }

  get serialsLoaded() {
    return this.serials.total > 0
  }

  get totalPubsClassIfNested() {
    return this.nestedComponentFormat ? 'd-block d-md-none' : ''
  }

  //
  // HOOKS
  //

  updated() {
    updateMathJax()
  }

  mounted() {
    // this.localPageSize = this.syncedPageSize
    // if (this.parentCurrentPage) {
    //   this.syncedCurrentPage = this.parentCurrentPage
    // }
    // if (this.parentPageSize) {
    //   this.syncedPageSize = this.parentPageSize
    // }
    updateMathJax()
  }

  //
  // METHODS
  //
  updatePageSize(pageSize: number) {
    this.syncedPageSize = pageSize
  }

  updateCurrentPage(pageNumber: number) {
    this.syncedCurrentPage = pageNumber
  }

  getMoreResults() {
    this.$emit('more')
  }

  sortBy(sortKey: string) {
    this.syncedSortKey = sortKey
  }

  onClickOrderByHandler(orderBy: string) {
    this.syncedOrderKey = orderBy.toLowerCase()
  }
}
