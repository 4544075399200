
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'

@Component
export default class SearchPagination extends Vue {
  @Prop({ type: Boolean, default: false }) loading!: boolean
  @Prop({ required: true }) totalItems!: number
  // @Prop({ type: Boolean, required: false, default: false }) unsubbed!: boolean

  @PropSync('currentPage', { type: Number }) syncedCurrentPage!: number
  @PropSync('pageSize', { type: Number }) syncedPageSize!: number

  pageSizes = [5, 10, 20, 50, 100]
  pageName = 'Testing'

  //
  // COMPUTED PROPERTIES
  //
  get totalPaginationRows() {
    // "rows" = how many items that pagination should be paging over.
    return this.totalItems > 1000 ? 1000 : this.totalItems
  }

  //
  // METHODS
  //
  updateSyncedPageSize(pageSize: string) {
    this.syncedPageSize = parseInt(pageSize)
    const localPageSize = localStorage.getItem(this.pageName + 'PageSize')
    if (localPageSize !== pageSize) {
      localStorage.setItem(this.pageName + 'PageSize', pageSize)
    }
  }

  updateCurrentPage(pageNumber: string) {
    this.syncedCurrentPage = parseInt(pageNumber)
  }

  mounted() {
    // Check local storage for existing page
    try {
      const routeName = this.$route.name
      if (routeName) {
        this.pageName = routeName
      }
    } catch {
    }
    const localPageSize = localStorage.getItem(this.pageName + 'PageSize')
    if (localPageSize) {
      this.updateSyncedPageSize(localPageSize)
    }
  }
}
